@font-face {
  font-family: "MADE Canvas";
  src: url("/public/MADECanvas-Black.woff2") format("woff2"),
    url("/public/MADECanvas-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MADE Canvas";
  src: url("/public/MADECanvas.woff2") format("woff2"),
    url("/public/MADECanvas.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MADE Canvas";
  src: url("/public/MADECanvas-Light.woff2") format("woff2"),
    url("/public/MADECanvas-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* :root {
  --var-gold: #bb9356
  --var-blue: #b6bfdd
  --var-blie-light: #deeaf5
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #490018;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
