$gold: #bb7000;

.save-the-date {
  &-title {
    text-align: center;
    font-size: 6rem;
    color: $gold;
    font-family: $onelove, serif;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    padding: 0;
    animation: fadeInBottom 1s 7s ease-in forwards;
    opacity: 0;

    @media screen and (max-width: 480px) {
      font-size: 4rem;
    }
  }
  &-subtitle {
    text-align: center;
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.8);
    font-family: "Open Sans";
    font-weight: 300;
    font-style: italic;
    margin: -40px 0 100px;
    padding: 0;
    animation: fadeInBottom 1.25s 7.5s ease-in forwards;
    opacity: 0;

    @media screen and (max-width: 480px) {
      font-size: 1.5rem;
      margin: -30px 0 0;
    }
  }
}

.brand {
  animation: names 1.5s 5.5s linear forwards;
  margin-top: -30px;
  max-height: 250px;
  opacity: 0;

  @media screen and (max-width: 480px) {
    max-height: 125px;
    // margin-top: 0;
  }
}

.getting-married {
  text-align: center;
  font-size: 4rem;
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.6);
  font-family: $onelove, serif;
  font-weight: 400;
  font-style: italic;
  animation: fadeIn 1s 6.25s ease-in forwards;
  opacity: 0;

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
  }
}

.countdown {
  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin-top: 25px;
    opacity: 0;
    animation: fadeInBottom 1s 8s ease-in forwards;

    @media screen and (max-width: 480px) {
      margin-top: 100px;
      padding: 0 15px;
    }
  }

  &-time {
    font-size: 42px;
    color: white;
    font-weight: bold;
    position: relative;
    margin-bottom: -10px;
    z-index: 1;
    text-align: center;
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;

    @media screen and (max-width: 480px) {
      font-size: 1.5rem;
    }
  }

  &-info {
    font-size: 20px;
    color: white;
    font-weight: 100;
    text-align: center;
    @media screen and (max-width: 480px) {
      font-size: 1rem;
    }
  }
}

.rose {
  position: fixed;
  left: 0;
  bottom: 0;
  max-height: 60vh;
  animation: toBottom 1s 5s linear forwards;
  &-path {
    stroke-dasharray: 2500;
    stroke-dashoffset: 2500;
    animation: dash 7s linear forwards;
  }

  @media screen and (max-width: 480px) {
    max-height: 70vh;
  }
}

.react-add-to-calendar__button {
  background-color: #f7f7f7;
  margin: 0 auto;
  width: 250px;
  border-radius: 5px;
  padding: 10px 15px;
}

.react-add-to-calendar__dropdown {
  background-color: #fff;
  margin-top: 5px;

  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding: 10px;
    }
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease-in forwards;
}
