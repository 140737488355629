.rsvp-content {
  h1 {
    font-family: $onelove, sans-serif;
    font-size: 90px;
    padding: 0;
    margin: 0;
  }
}
.content-row {
  padding: 100px 0;
  max-width: 1100px;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  // border-top: 1px solid rgba(0, 0, 0, 0.15);

  .toast-image {
    min-width: 300px;
    min-height: 600px;
    height: 100%;
    width: 100%;
    background-size: 220%;
    background-repeat: no-repeat;
    background-position: 60%;
  }

  img {
    max-width: 100%;
  }
  @media screen and (max-width: 768px) {
    h2 {
      padding-top: 30px;
    }
    p,
    ul {
      padding-bottom: 30px;
    }
  }
}

.introduction {
  background-image: url("../img/wedding.jpeg");
  height: 100vh;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    max-width: 500px;

    @media screen and (max-width: 768px) {
      max-width: 100vw;
    }
  }

  .arrow-down {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    z-index: 2;
    color: white;
    font-size: 36px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
}

.seperator {
  display: flex;
  justify-content: center;
}
