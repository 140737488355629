@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Satisfy&display=swap");
@import "~antd/dist/antd.css";
@import "animations.scss";

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
}

body {
  font-size: 16px;
}

footer {
  @media screen and (max-width: 768px) {
    height: 100% !important;
  }
  h4 {
    a {
      color: white;

      &:hover {
        color: #40a9ff;
      }
    }
  }

  svg {
    max-height: 140px;
    margin: 50px 0;

    @media screen and (min-width: 768px) {
      margin: 0 0 0 -75px;
    }
  }
}

.save-the-date {
  background-color: #4e0e21;
  height: 100vh;
}
@import "./fonts/fonts.scss";
@import "savethedate.scss";
@import "rsvp.scss";
